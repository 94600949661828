import { css, keyframes } from '@emotion/react';
import React from 'react';
import tw from 'twin.macro';

import { FOOTER_LINKS } from '@chartsy/shared/data';

import IconFacebook from './Icon/Facebook';
import IconInstagram from './Icon/Instagram';
import IconTwitter from './Icon/Twitter';
import Logo from './Logo';
import ResourceLink from './ResourceLink';
import { STORE_GRADIENT_CSS, PAGE_CONTAINER_CSS } from './Styled';
import TextLink from './TextLink';

const ICON_ANIMATION_KEYFRAMES = keyframes`
  33% {
    transform: rotate(-0.03turn);
  }
  66% {
    transform: rotate(0.08turn);
  }
`;

const ICON_CSS = css`
  &:hover {
    animation: ${ICON_ANIMATION_KEYFRAMES} 0.7s ease 1;
  }
`;

const List = tw.ul`space-y-3 md:space-y-6 mt-3 md:mt-6`;

const Footer = () => (
  <footer css={STORE_GRADIENT_CSS} tw="mt-16 relative">
    <div
      css={PAGE_CONTAINER_CSS}
      tw="flex flex-col md:flex-row max-w-6xl mx-auto text-purple-700 py-8 md:py-16"
    >
      <div tw="md:w-52 order-2 md:order-2 mt-8 md:mt-0">
        <ResourceLink to="home">
          <Logo tw="inline-block" />
        </ResourceLink>
        <p tw="mt-4">
          Chartsy is the digital storefront for cross stitch patterns and tools.
        </p>
        <div tw="mt-4 text-purple-400">
          © {new Date().getFullYear()} Chartsy, LLC
        </div>
        <div tw="flex space-x-8 mt-10">
          <IconTwitter css={ICON_CSS} tw="h-12 w-12" />
          <IconInstagram css={ICON_CSS} tw="h-12 w-12" />
          <IconFacebook css={ICON_CSS} tw="h-12 w-12" />
        </div>
      </div>
      <div tw="grid grid-cols-2 lg:grid-cols-4 gap-8 md:ml-44 order-1 md:order-2">
        {FOOTER_LINKS.map((section) => (
          <div key={section.title}>
            <TextLink hue="purple" to={section.to} tw="font-bold">
              {section.title}
            </TextLink>
            <List>
              {section.links.map((link) => (
                <li key={link.title}>
                  <TextLink hue="purple" to={link.to}>
                    {link.title}
                  </TextLink>
                </li>
              ))}
            </List>
          </div>
        ))}
      </div>
    </div>
  </footer>
);

export default Footer;
