import React from 'react';

function IconTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={48}
        height={48}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          stroke="currentColor"
          strokeWidth={4}
        />
      </g>
      <path
        d="M33.301 18.377a4.92 4.92 0 002.066-2.741 9.116 9.116 0 01-2.986 1.202c-.855-.964-2.077-1.565-3.43-1.565-2.595 0-4.698 2.22-4.698 4.956 0 .389.039.768.12 1.13-3.905-.208-7.368-2.18-9.687-5.18a5.154 5.154 0 00-.636 2.493c0 1.719.83 3.236 2.09 4.127a4.53 4.53 0 01-2.13-.62v.061c0 2.403 1.62 4.407 3.772 4.86-.395.117-.81.175-1.24.175-.302 0-.598-.03-.883-.088.597 1.968 2.333 3.404 4.39 3.442-1.61 1.33-3.636 2.122-5.837 2.122-.379 0-.754-.022-1.121-.069 2.08 1.406 4.55 2.227 7.205 2.227 8.646 0 13.37-7.553 13.37-14.104 0-.215-.002-.43-.011-.64A9.824 9.824 0 0036 17.596c-.843.394-1.75.661-2.699.78z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconTwitter;
