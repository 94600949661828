import React from 'react';

function IconInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={48}
        height={48}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          stroke="currentColor"
          strokeWidth={4}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c-3.26 0-3.668.014-4.948.072-1.277.059-2.15.261-2.912.558a5.883 5.883 0 00-2.126 1.384 5.88 5.88 0 00-1.384 2.126c-.297.763-.5 1.635-.558 2.912C12.014 20.332 12 20.741 12 24c0 3.26.014 3.668.072 4.948.059 1.277.261 2.15.558 2.912a5.88 5.88 0 001.384 2.126 5.88 5.88 0 002.126 1.384c.763.297 1.635.5 2.912.558 1.28.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 1.277-.058 2.15-.261 2.912-.558a5.88 5.88 0 002.126-1.384 5.883 5.883 0 001.384-2.125c.297-.764.5-1.636.558-2.913.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.058-1.277-.261-2.15-.558-2.912a5.883 5.883 0 00-1.384-2.126 5.883 5.883 0 00-2.125-1.384c-.764-.297-1.636-.5-2.913-.558C27.668 12.014 27.259 12 24 12zm0 2.162c3.204 0 3.584.012 4.849.07 1.17.054 1.805.249 2.228.413.56.218.96.478 1.38.898.42.42.68.82.898 1.38.164.423.36 1.058.413 2.228.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.054 1.17-.25 1.805-.413 2.228-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.38.898-.423.164-1.058.36-2.228.413-1.265.058-1.645.07-4.85.07-3.203 0-3.583-.012-4.848-.07-1.17-.053-1.805-.249-2.228-.413a3.717 3.717 0 01-1.38-.898c-.42-.42-.68-.82-.898-1.38-.164-.423-.36-1.058-.413-2.228-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.053-1.17.249-1.805.413-2.228.218-.56.478-.96.898-1.38.42-.42.82-.68 1.38-.898.423-.164 1.058-.36 2.228-.413 1.265-.058 1.645-.07 4.849-.07zm-6.143 9.862a6.167 6.167 0 1112.334 0 6.167 6.167 0 01-12.334 0zm6.167 4.003a4.003 4.003 0 110-8.006 4.003 4.003 0 010 8.006zM30.38 19a1.429 1.429 0 100-2.857 1.429 1.429 0 000 2.857z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconInstagram;
