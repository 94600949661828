import React from 'react';

function IconFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={48}
        height={48}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          clipRule="evenodd"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
          stroke="currentColor"
          strokeWidth={4}
        />
      </g>
      <path
        d="M25.434 37.09h-5.32V24.545h-2.66V20.22h2.66v-2.596c0-3.527 1.5-5.625 5.766-5.625h3.55v4.325h-2.219c-1.66 0-1.77.604-1.77 1.732l-.008 2.164h4.022l-.471 4.323h-3.55V37.09z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconFacebook;
